/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import * as styles from "./Popup.module.scss";
import WaveIcon from "../../assets/images/icons/buta-wave.gif";
import MenuContext from "../../context/menu-context";
import Translator from "../../utilities/Translator";
import { keys } from "../../utilities/translations";
const Popup = (props) => {
  const { media, mediaType, slug, t } = props;
  const cookieName = `ramen-kagura-${slug}-popup`;
  const [cookies, setCookie] = useCookies([cookieName]);
  const [showPopup, setShowPopup] = useState(null);
  const menuContext = useContext(MenuContext);
  const { currentLangCode } = menuContext;
  const hideModal = (e) => {
    // only set when interacted with
    setCookie(cookieName, true, { path: "/", maxAge: 60 * 60 * 24 });
    setShowPopup(false);
  };

  useEffect(() => {
    if (!cookies[cookieName] && media) {
      setShowPopup(true);
    }
  }, []);

  const renderPopupMedia = () => {
    if (mediaType === "image") {
      return (
        <figure>
          <img src={media} alt="" />
        </figure>
      );
    }
    if (mediaType === "video") {
      return <video src={media} playsInline muted loop autoPlay></video>;
    }
    return null;
  };
  return (
    <>
      {showPopup &&
        ReactDom.createPortal(
          <div role="dialog" className={styles.popupWrap}>
            <div
              className={styles.backdrop}
              role="button"
              onClick={hideModal}
              onKeyPress={hideModal}
              tabIndex="-1"
            >
              {t(keys.CLOSE_POPUP_LABEL, currentLangCode)}
            </div>
            <div className={styles.popupInnerWrap}>
              <div className={styles.popupBody}>{renderPopupMedia()}</div>
              <div className={styles.popupFooter}>
                <div className={styles.waveIconWrap}>
                  <img src={WaveIcon} alt="" />
                </div>
                <div className={styles.closeModalWrap}>
                  <button className={styles.closeModal} onClick={hideModal}>
                    {t(keys.CLOSE_POPUP_LABEL, currentLangCode)}
                  </button>
                </div>
              </div>
            </div>
          </div>,
          document.getElementById("overlays")
        )}
    </>
  );
};
Popup.propTypes = {
  media: PropTypes.any,
  mediaType: PropTypes.oneOf(["image", "video"]),
  slug: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
export default Translator(Popup);
