import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import MenuBar from "../partials/global/MenuBar";
import MenuContext from "../context/menu-context";
import BackgroundImage from "../components/elements/BackgroundImage";
import ContactForm from "../partials/pages/ContactForm";
import BackgroundTint from "../components/presentation/BackgroundTint";
import { getContactUsPopupMedia } from "../utilities/functions";
import Popup from "../partials/global/Popup";
import Translator from "../utilities/Translator";
import { keys } from "../utilities/translations";
function ContactUs(props) {
  const {
    data: { contactUsPage, placeholderBg },
    t,
  } = props;
  const {
    featuredImage: { node: bgImage },
  } = contactUsPage;
  const ctx = useContext(MenuContext);
  const { currentLangCode } = ctx;
  const backgroundImage = bgImage || {
    localFile: placeholderBg,
    altText: "Contact Us",
  };
  const { slug } = contactUsPage;
  const { media, mediaType } = getContactUsPopupMedia(currentLangCode);
  return (
    <section className="full-height-section">
      <Seo post={contactUsPage} />
      <MenuBar />
      <Popup slug={slug} media={media} mediaType={mediaType} />
      <BackgroundImage
        image={backgroundImage.localFile}
        alt={backgroundImage.altText}
      />
      <BackgroundTint />
      <div className="section-content py-5 flex-column">
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-12 px-0">
              <div className="contact-content text-center color-white">
                <span className="logo-ramen-kagura" />
                <h2
                  className="contact-title"
                  dangerouslySetInnerHTML={{
                    __html: t(keys.CONTACT_FORM_TITLE, currentLangCode),
                  }}
                ></h2>
                <h4 className="contact-note mb-4">
                  {t(keys.CONTACT_FORM_NOTE, currentLangCode)}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </section>
  );
}

export const query = graphql`
  query ($pageId: String) {
    contactUsPage: wpPage(id: { eq: $pageId }) {
      seo {
        ...YoastPageSEOContent
      }
      id
      title
      slug
      id
      title
      slug
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          altText
        }
      }
    }
    placeholderBg: file(
      relativePath: { eq: "placeholders/contact-us-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

ContactUs.propTypes = {
  data: PropTypes.shape({
    contactUsPage: PropTypes.shape({
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          altText: PropTypes.string.isRequired,
          localFile: PropTypes.shape({}).isRequired,
        }),
      }).isRequired,
    }),
    placeholderBg: PropTypes.shape({}).isRequired,
  }).isRequired,
};
export default Translator(ContactUs);
