import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ReactDom from 'react-dom';
import ReactDomServer from 'react-dom/server';
import Modal from '../../components/UI/Modal';
import MenuContext from '../../context/menu-context';
import * as styles from './ContactForm.module.scss';
import Translator from '../../utilities/Translator';
import { keys } from '../../utilities/translations';

function ContactForm(props) {
  const queryData = useStaticQuery(graphql`
  query {
    restaurants: allWpRestaurant {
      nodes {
        id
        name
        restaurantDetails {
          contactEmail
        }
        language {
          id
          code
        }
      }
    }
  }
  `);
  const { t } = props;
  const ctx = useContext(MenuContext);
  const { currentLangCode } = ctx;

  // Filter restaurants based on current language and contact email
  const {
    restaurants: {
      nodes: restaurants,
    },
  } = queryData;
  const filteredRestaurants = restaurants.filter(
    (restaurant) => (restaurant.language.code.toLowerCase() === currentLangCode
      && restaurant.restaurantDetails.contactEmail),

  );

  const [showModal, setShowModal] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const restaurantEmailRef = useRef(null);
  const restaurantSelectRef = useRef(null);
  const restaurantChangeHandler = () => {
    const selectedRestaurant = filteredRestaurants.find(
      (el) => el.name === restaurantSelectRef.current.value,
    );
    restaurantEmailRef.current.value = selectedRestaurant.restaurantDetails.contactEmail;
  };

  const prepareModal = (res) => {
    if (res.status === 'success') {
      // const message = res.messages.find(() => true);
      const message = (
        <div className="text-center">
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: t(keys.THANKYOU_MESSAGE, currentLangCode) }} />
        </div>
      );
      setModalContent(ReactDomServer.renderToString(message));
    } else if (res.status === 'error') {
      const message = (
        <>
          <h4 className="text-center">{t(keys.FORM_ERROR_MESSAGES_TITLE, currentLangCode)}</h4>
          <ul>
            {res?.messages?.required.map((item, index) => (
              <li key={`index-${index.toString()}`}>{item}</li>
            ))}
          </ul>
        </>
      );
      setModalContent(ReactDomServer.renderToString(message));
    } else {
      const message = (
        <p dangerouslySetInnerHTML={{ __html: t(keys.FORM_ERROR_MESSAGE, currentLangCode) }} />
      );
      setModalContent(ReactDomServer.renderToString(message));
    }
  };

  const closeModalHandler = () => {
    setShowModal(false);
    // After submission the hidden email field loses value
    // Add value to it again when modal closes
    restaurantChangeHandler();
  };

  const submitHandler = async (e) => {
    e.persist();
    e.preventDefault();

    const data = new FormData(e.target);
    const formObject = Object.fromEntries(data.entries());
    const SUBMIT_URL = 'https://woocommerce-468041-2297235.cloudwaysapps.com/wp-admin/admin-ajax.php?action=contact_form';
    await fetch(SUBMIT_URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: new URLSearchParams(formObject).toString(),
    })
      .then((res) => res.json())
      .then((json) => {
        prepareModal(json);
        setShowModal(true);
      });
  };
  return (
    <form className={styles.contactFormWrap} onSubmit={submitHandler}>
      {showModal
        && ReactDom.createPortal(
          <Modal content={modalContent} onClose={closeModalHandler} />,
          document.getElementById('overlays'),
        )}
      <div className={[styles.contactFormContainer, 'p-4'].join(' ')}>
        <div className={[styles.contactFormRow, 'row'].join(' ')}>
          <div className="col-12 col-md-6 mb-3 px-4">
            <label className="w-100" htmlFor="user_name">
              <span className="label-text">{t(keys.FORM_FIELD_NAME_LABEL, currentLangCode)}</span>
              <input
                className="form-control"
                type="text"
                name="user_name"
                id="user_name"
                required
              />
            </label>
          </div>
          <div className="col-12 col-md-6 mb-3 px-4">
            <label className="w-100" htmlFor="user_email">
              <span className="label-text">{t(keys.FORM_FIELD_EMAIL_LABEL, currentLangCode)}</span>
              <input
                className="form-control"
                type="email"
                name="user_email"
                id="user_email"
                required
              />
            </label>
          </div>
          <div className="col-12 col-md-6 mb-3 px-4">
            <label className="w-100" htmlFor="restaurant">
              <span className="label-text">{t(keys.FORM_FIELD_RESTAURANT_LABEL, currentLangCode)}</span>
              <select
                ref={restaurantSelectRef}
                className="form-select"
                required
                name="restaurant"
                id="restaurant"
                defaultValue=""
                onChange={restaurantChangeHandler}
              >
                <option value="" disabled>{t(keys.FORM_FIELD_RESTAURANT_PLACEHOLDER, currentLangCode)}</option>
                {filteredRestaurants.map((restaurant) => (
                  <option key={restaurant.id} value={restaurant.name}>{restaurant.name}</option>
                ))}
              </select>
              <input ref={restaurantEmailRef} type="hidden" name="to_email" readOnly value="" />
            </label>
          </div>

          <div className="col-12 col-md-6 mb-3 px-4">
            <label className="w-100" htmlFor="restaurant">
              <span className="label-text">{t(keys.FORM_FIELD_TOPIC_LABEL, currentLangCode)}</span>
              <select className="form-select" required name="query" id="query" defaultValue="">
                <option value="" disabled>{t(keys.FORM_FIELD_TOPIC_PLACEHOLDER, currentLangCode)}</option>
                <option value={t(keys.FORM_FIELD_TOPIC_OPTION_1, currentLangCode)}>{t(keys.FORM_FIELD_TOPIC_OPTION_1, currentLangCode)}</option>
                <option value={t(keys.FORM_FIELD_TOPIC_OPTION_2, currentLangCode)}>{t(keys.FORM_FIELD_TOPIC_OPTION_2, currentLangCode)}</option>
                <option value={t(keys.FORM_FIELD_TOPIC_OPTION_3, currentLangCode)}>{t(keys.FORM_FIELD_TOPIC_OPTION_3, currentLangCode)}</option>
                <option value={t(keys.FORM_FIELD_TOPIC_OPTION_4, currentLangCode)}>{t(keys.FORM_FIELD_TOPIC_OPTION_4, currentLangCode)}</option>
              </select>
            </label>
          </div>
          <div className="col-12 px-4">
            <label className="w-100" htmlFor="user_message">
              <span className="label-text">{t(keys.FORM_FIELD_INFORMATION_LABEL, currentLangCode)}</span>
              <textarea
                className="form-control"
                name="user_message"
                id="user_message"
                cols="30"
                rows="8"
              />
            </label>
          </div>
        </div>
      </div>
      <div className="container-fluid px-4">
        <div className="row">
          <div className="col-12 my-4 text-center">
            <button className={styles.contactFormSubmitButton} type="submit">
            {t(keys.FORM_SUBMIT_TEXT, currentLangCode)}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

ContactForm.propTypes = {
  t: PropTypes.func.isRequired,
};
export default Translator(ContactForm);
